import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'popper.js';
import 'bootstrap';
// import Siema from 'siema';
// import Scrollbar from 'smooth-scrollbar';
// import PhotoSwipe from 'photoswipe';

// Scrollbar.init(document.querySelector('#my-scrollbar'));

/* Hamburger menu */
$(document).ready(function() {
    $('#hamburgerMenu').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.navmobile-wrapper').removeClass('active');
            // $('body').css('position','unset');
            // $('body').css('top','unset');
        } else {
            $(this).addClass('active');
            $('.navmobile-wrapper').addClass('active');
            // $('body').css('position','fixed');
            // $('body').css('top','0');
        }
    });
})


$(document).ready(function() {
  $('.dropdown').click(function() {
      $('data-toggle', 'dropdown').addClass('active')
  });
  $(window).resize(function () {
    if ($(window).width() >= 992) {
      // $('[data-toggle="dropdown"]');
      $(".dropdown-toggle").removeAttr("data-toggle", "dropdown");
    }
  });
})

/* Video */

$(function() {
	var headerHeight, videoHeight, calc;
	function headerVideoHeight() {
		headerHeight = $('section').outerHeight();
		videoHeight = $('section .header-video iframe').height();
		calc = headerHeight / videoHeight;
		if (window.innerWidth < ($('section').outerHeight() * 1.78)) {
			// 1.78 == 1920 / 1080
			$('section .header-video iframe').css('transform','translateY(-50%) scale(' + calc +')');
		} else {
			$('section .header-video iframe').css('transform','translateY(-50%)',);
		}
	}

	headerVideoHeight();
	
	var resizeTimer;  
	$(window).on('resize',function() {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			headerVideoHeight();
		}, 150);
	});
  
  $('.pause').click(function(e) {
    e.preventDefault();
    if ($(this).hasClass('stopped')) {
      $('iframe').vimeo('play');
      $(this).removeClass('stopped');
    } else {
      $('iframe').vimeo('pause');
      $(this).addClass('stopped');
    }
  });
  
  $('.volume').click(function(e) {
    e.preventDefault();
    if ($(this).hasClass('muted')) {
      $('iframe').vimeo('setVolume',1);
      $(this).removeClass('muted');
    } else {
      $('iframe').vimeo('setVolume',0);
      $(this).addClass('muted');
    }
  });
});


/* sticky Navbar */

$(document).ready(function() {
  var stickyNavTop = $('#navbar').offset().top;

  var stickyNav = function(){
    var scrollTop = $(window).scrollTop();

    if (scrollTop > stickyNavTop) { 
      $('#navbar').addClass('sticky');
    } else {
      $('#navbar').removeClass('sticky'); 
    }
  };

  stickyNav();

  $(window).scroll(function() {
    stickyNav();
  });
});

/* Register Fixed Form */


$(document).ready(function() {
  $("#fixed-form-container .body").hide();
  $("#fixed-form-container .button").click(function () {
    $(this).next("#fixed-form-container div").slideToggle(400);
    $(this).toggleClass("expanded");
  });
});
